.EULA {
  max-height: 100vh;
  overflow: auto;
  padding-top: 60px;

  .WordSection1 {
    width: 900px;
    max-width: 80%;
    margin: auto;
    margin-top: 75px;
  }

  .msocomanchor,
		.msocomoff,
		.MsoCommentText,
		.msocomtxt,
		.MsoCommentReference {
			display: none !important;
			visibility: hidden !important;
			opacity: 0;
		}



		/* Font Definitions */
		@font-face {
			font-family: "Cambria Math";
			panose-1: 2 4 5 3 5 4 6 3 2 4;
			mso-font-charset: 0;
			mso-generic-font-family: roman;
			mso-font-pitch: variable;
			mso-font-signature: -536870145 1107305727 0 0 415 0;
		}

		@font-face {
			font-family: Calibri;
			panose-1: 2 15 5 2 2 2 4 3 2 4;
			mso-font-charset: 0;
			mso-generic-font-family: swiss;
			mso-font-pitch: variable;
			mso-font-signature: -536859905 -1073732485 9 0 511 0;
		}

		@font-face {
			font-family: Tahoma;
			panose-1: 2 11 6 4 3 5 4 4 2 4;
			mso-font-charset: 0;
			mso-generic-font-family: swiss;
			mso-font-pitch: variable;
			mso-font-signature: -520081665 -1073717157 41 0 66047 0;
		}

		/* Style Definitions */
		p.MsoNormal,
		li.MsoNormal,
		div.MsoNormal {
			mso-style-unhide: no;
			mso-style-qformat: yes;
			mso-style-parent: "";
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 10.0pt;
			margin-left: 0in;
			line-height: 115%;
			mso-pagination: widow-orphan;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		p.MsoCommentText,
		li.MsoCommentText,
		div.MsoCommentText {
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-style-link: "Comment Text Char";
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 10.0pt;
			margin-left: 0in;
			mso-pagination: widow-orphan;
			font-size: 10.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		p.MsoHeader,
		li.MsoHeader,
		div.MsoHeader {
			mso-style-priority: 99;
			mso-style-link: "Header Char";
			margin: 0in;
			margin-bottom: .0001pt;
			mso-pagination: widow-orphan;
			tab-stops: center 3.25in right 6.5in;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		p.MsoFooter,
		li.MsoFooter,
		div.MsoFooter {
			mso-style-priority: 99;
			mso-style-link: "Footer Char";
			margin: 0in;
			margin-bottom: .0001pt;
			mso-pagination: widow-orphan;
			tab-stops: center 3.25in right 6.5in;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		span.MsoCommentReference {
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-ansi-font-size: 8.0pt;
			mso-bidi-font-size: 8.0pt;
		}

		p.MsoCommentSubject,
		li.MsoCommentSubject,
		div.MsoCommentSubject {
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-style-parent: "Comment Text";
			mso-style-link: "Comment Subject Char";
			mso-style-next: "Comment Text";
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 10.0pt;
			margin-left: 0in;
			mso-pagination: widow-orphan;
			font-size: 10.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
			font-weight: bold;
		}

		p.MsoAcetate,
		li.MsoAcetate,
		div.MsoAcetate {
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-style-link: "Balloon Text Char";
			margin: 0in;
			margin-bottom: .0001pt;
			mso-pagination: widow-orphan;
			font-size: 8.0pt;
			font-family: "Tahoma", sans-serif;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
		}

		p.MsoListParagraph,
		li.MsoListParagraph,
		div.MsoListParagraph {
			mso-style-priority: 34;
			mso-style-unhide: no;
			mso-style-qformat: yes;
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 10.0pt;
			margin-left: .5in;
			mso-add-space: auto;
			line-height: 115%;
			mso-pagination: widow-orphan;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		p.MsoListParagraphCxSpFirst,
		li.MsoListParagraphCxSpFirst,
		div.MsoListParagraphCxSpFirst {
			mso-style-priority: 34;
			mso-style-unhide: no;
			mso-style-qformat: yes;
			mso-style-type: export-only;
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 0in;
			margin-left: .5in;
			margin-bottom: .0001pt;
			mso-add-space: auto;
			line-height: 115%;
			mso-pagination: widow-orphan;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		p.MsoListParagraphCxSpMiddle,
		li.MsoListParagraphCxSpMiddle,
		div.MsoListParagraphCxSpMiddle {
			mso-style-priority: 34;
			mso-style-unhide: no;
			mso-style-qformat: yes;
			mso-style-type: export-only;
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 0in;
			margin-left: .5in;
			margin-bottom: .0001pt;
			mso-add-space: auto;
			line-height: 115%;
			mso-pagination: widow-orphan;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		p.MsoListParagraphCxSpLast,
		li.MsoListParagraphCxSpLast,
		div.MsoListParagraphCxSpLast {
			mso-style-priority: 34;
			mso-style-unhide: no;
			mso-style-qformat: yes;
			mso-style-type: export-only;
			margin-top: 0in;
			margin-right: 0in;
			margin-bottom: 10.0pt;
			margin-left: .5in;
			mso-add-space: auto;
			line-height: 115%;
			mso-pagination: widow-orphan;
			font-size: 11.0pt;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		span.CommentTextChar {
			mso-style-name: "Comment Text Char";
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-style-unhide: no;
			mso-style-locked: yes;
			mso-style-link: "Comment Text";
			mso-ansi-font-size: 10.0pt;
			mso-bidi-font-size: 10.0pt;
		}

		span.CommentSubjectChar {
			mso-style-name: "Comment Subject Char";
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-style-unhide: no;
			mso-style-locked: yes;
			mso-style-parent: "Comment Text Char";
			mso-style-link: "Comment Subject";
			mso-ansi-font-size: 10.0pt;
			mso-bidi-font-size: 10.0pt;
			font-weight: bold;
		}

		span.BalloonTextChar {
			mso-style-name: "Balloon Text Char";
			mso-style-noshow: yes;
			mso-style-priority: 99;
			mso-style-unhide: no;
			mso-style-locked: yes;
			mso-style-link: "Balloon Text";
			mso-ansi-font-size: 8.0pt;
			mso-bidi-font-size: 8.0pt;
			font-family: "Tahoma", sans-serif;
			mso-ascii-font-family: Tahoma;
			mso-hansi-font-family: Tahoma;
			mso-bidi-font-family: Tahoma;
		}

		span.HeaderChar {
			mso-style-name: "Header Char";
			mso-style-priority: 99;
			mso-style-unhide: no;
			mso-style-locked: yes;
			mso-style-link: Header;
		}

		span.FooterChar {
			mso-style-name: "Footer Char";
			mso-style-priority: 99;
			mso-style-unhide: no;
			mso-style-locked: yes;
			mso-style-link: Footer;
		}

		.MsoChpDefault {
			mso-style-type: export-only;
			mso-default-props: yes;
			font-family: "Calibri", sans-serif;
			mso-ascii-font-family: Calibri;
			mso-ascii-theme-font: minor-latin;
			mso-fareast-font-family: Calibri;
			mso-fareast-theme-font: minor-latin;
			mso-hansi-font-family: Calibri;
			mso-hansi-theme-font: minor-latin;
			mso-bidi-font-family: "Times New Roman";
			mso-bidi-theme-font: minor-bidi;
		}

		.MsoPapDefault {
			mso-style-type: export-only;
			margin-bottom: 10.0pt;
			line-height: 115%;
		}

		/* Page Definitions */
		// @page {
		// 	mso-oto-separator: url("end-user-licensing-agreement-v2_files/header.html") fs;
		// 	mso-oto-continuation-separator: url("end-user-licensing-agreement-v2_files/header.html") fcs;
		// 	mso-oto-separator: url("end-user-licensing-agreement-v2_files/header.html") es;
		// 	mso-oto-continuation-separator: url("end-user-licensing-agreement-v2_files/header.html") ecs;
		// }

		@page WordSection1 {
			size: 8.5in 11.0in;
			margin: 1.0in 1.0in 1.0in 1.0in;
			mso-oto-margin: .5in;
			mso-oto-margin: .5in;
			// mso-oto: url("end-user-licensing-agreement-v2_files/header.html") f1;
			mso-paper-source: 0;
		}

		div.WordSection1 {
			page: WordSection1;
		}

		/* List Definitions */
		@list l0 {
			mso-list-id: 32192552;
			mso-list-type: hybrid;
			mso-list-template-ids: 1571866488 1440262136 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l0:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l0:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l0:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l0:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l0:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l0:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l0:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l0:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l0:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l1 {
			mso-list-id: 216011121;
			mso-list-type: hybrid;
			mso-list-template-ids: 254337210 -37433444 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l1:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l1:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l1:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l1:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l1:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l1:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l1:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l1:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l1:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l2 {
			mso-list-id: 1250115393;
			mso-list-type: hybrid;
			mso-list-template-ids: -2008022932 67698703 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l2:level1 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			text-indent: -.25in;
		}

		@list l2:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			text-indent: -.25in;
		}

		@list l2:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			text-indent: -9.0pt;
		}

		@list l2:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			text-indent: -.25in;
		}

		@list l2:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			text-indent: -.25in;
		}

		@list l2:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			text-indent: -9.0pt;
		}

		@list l2:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			text-indent: -.25in;
		}

		@list l2:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			text-indent: -.25in;
		}

		@list l2:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			text-indent: -9.0pt;
		}

		@list l3 {
			mso-list-id: 1510022547;
			mso-list-type: hybrid;
			mso-list-template-ids: 1298417686 -1228908644 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l3:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l3:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l3:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l3:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l3:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l3:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l3:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l3:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l3:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l4 {
			mso-list-id: 1727949430;
			mso-list-type: hybrid;
			mso-list-template-ids: 1632136782 -2054812674 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l4:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l4:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l4:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l4:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l4:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l4:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l4:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l4:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l4:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l5 {
			mso-list-id: 1854998779;
			mso-list-type: hybrid;
			mso-list-template-ids: 829867372 1018601582 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l5:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l5:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l5:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l5:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l5:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l5:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l5:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l5:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l5:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l6 {
			mso-list-id: 1880361243;
			mso-list-type: hybrid;
			mso-list-template-ids: -220581076 1440262136 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l6:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l6:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l6:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l6:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l6:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l6:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l6:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l6:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l6:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l7 {
			mso-list-id: 1900826510;
			mso-list-type: hybrid;
			mso-list-template-ids: -330908970 840055960 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l7:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l7:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l7:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l7:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l7:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l7:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l7:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l7:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l7:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		@list l8 {
			mso-list-id: 2123064083;
			mso-list-type: hybrid;
			mso-list-template-ids: -268920470 -1141716694 67698713 67698715 67698703 67698713 67698715 67698703 67698713 67698715;
		}

		@list l8:level1 {
			mso-level-number-format: alpha-lower;
			mso-level-text: "\(%1\)";
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: .75in;
			text-indent: -.25in;
		}

		@list l8:level2 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 1.25in;
			text-indent: -.25in;
		}

		@list l8:level3 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 1.75in;
			text-indent: -9.0pt;
		}

		@list l8:level4 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.25in;
			text-indent: -.25in;
		}

		@list l8:level5 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 2.75in;
			text-indent: -.25in;
		}

		@list l8:level6 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 3.25in;
			text-indent: -9.0pt;
		}

		@list l8:level7 {
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 3.75in;
			text-indent: -.25in;
		}

		@list l8:level8 {
			mso-level-number-format: alpha-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: left;
			margin-left: 4.25in;
			text-indent: -.25in;
		}

		@list l8:level9 {
			mso-level-number-format: roman-lower;
			mso-level-tab-stop: none;
			mso-level-number-position: right;
			margin-left: 4.75in;
			text-indent: -9.0pt;
		}

		ol {
			margin-bottom: 0in;
		}

		ul {
			margin-bottom: 0in;
		}


}