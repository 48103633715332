.PasswordUpdateModalComponent {
  --dark: #313363;
  --bg-dropdown: #fff;
  --color: #282939;
  --cacel-btn-color: #313363;
  --file-bg: #f2f6f9;
  --file-txt: #9bafca;
  --cancel-btn-bg: #f2f6f9;
  --preview-bg: #ebeef3;
  --preview-border: 1px solid #e1e6ed;
  --preview-color: #39406077;
}

body.dark .PasswordUpdateModalComponent {
  --dark: #384f92;
  --bg-dropdown: #151517;
  --color: #ffffff;
  --cacel-btn-color: #fff;
  --file-bg: #242435;
  --file-txt: #b9b9b9;
  --cancel-btn-bg: #3a3a49;
  --preview-bg: #151517;
  --preview-border: 1px solid #3b3c3e;
  --preview-color: #9bafca;

  span {
    color: #b9b9b9;
  }

  input {
    background-color: #151517;
    border: 1px solid #3b3c3e;

    color: #9bafca;
  }
}

.PasswordUpdateModalComponent {
  width: 478px !important;
  max-width: 90vw !important;
  margin: auto !important;
  max-height: 478px !important;

  .modal-content {
    height: unset;
  }
  .modal-body {
    padding: 15px 20px !important;

    &.update {
      height: unset !important;
    }

    &.success {
      max-height: 70px !important;
    }
  }

  .close {
    max-width: 30px;
  }

  .modal-title {
    display: flex;
    align-items: center;

    img {
      margin-right: 8px;
    }
  }

  .modal-footer {
    border-top: none !important;
    padding: 0 0.75rem 0.75rem 0.75rem !important;

    button {
      font-family: inherit !important;
      font-weight: bold;
      width: 140px !important;
      margin-left: 15px !important;
    }
  
    .cancel_btn {
      background-color: var(--cancel-btn-bg) !important;
      color: var(--cacel-btn-color) !important;
      width: 215px !important;
    }
  
  }
}
