.LogosComponent {
  // display: none;

  h2 {
    font-size: 26px !important;
  }

  .logo {
    margin-bottom: 30px;

    .content {
      display: flex;
      align-items: center;

      button {
        margin: 0px 35px;
        width: 125px;
      }
    }
  }

  .logo-main .preview {
    background-color: #313363;
    padding: 21px 16px;

    img {
      max-height: 45px;
      max-width: 45px;
      object-fit: contain;
    }
  }

  .logo-main-dark .preview {
    background-color: #000000;
    padding: 21px 16px;

    img {
      max-height: 45px;
      max-width: 45px;
      object-fit: contain;
    }
  }

  .logo-aboutus .preview {
    display: flex;
    align-items: center;
    background-color: #313363;
    padding: 30px;

    .square {
      height: 100px;
      width: 100px;
      object-fit: contain;
    }

    .rectangle {
      max-width: 320px;
      max-height: 65px;
      margin-left: 10px;
      object-fit: contain;
    }
  }

  .logo-large .preview {
    background-color: #f2f6f9;
    padding: 20px;

    img {
      width: auto;
      max-height: 50px;
      max-width: 250px;
      object-fit: contain;
    }
  }

  .logo-large-dark .preview, .more-logo-dark .preview {
    background-color: #363760;
    padding: 20px;

    img {
      width: auto;
      max-height: 50px;
      max-width: 250px;
      object-fit: contain;
    }
  }
  
  .more-logo-dark .preview{
    background-color: #000;
  }

  .logo.text-editor {
    .preview {
      min-height: 100px;
      min-width: 100px;
      background-color: #fff;
      columns: #000;
    }
  }
}