.AboutTextComponent {
  max-width: 760px;
  margin: auto;

  h2 {
    font-size: 26px !important;
  }

  .btn-content {
    text-align: center;
  }
}
