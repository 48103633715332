$white: rgb(255, 255, 255);
$white-50: #ffffff;
$pale-blue: #E1E6ED;
$alice-blue: #f2f6f9;
$silver: #b9b9b9;
$pale-blue-dark: #9bafca;
$shuttle-grey: #656a71;
$raven: #6d737b;
$tencSeriot: #999999;
$east-bay: #535469;
$vulcan: #3b3c3e;
$payne-grey: #3a3a49;
$dark-blue: #394060;
$ink-blue-50: #282939;
$jaguar: #242435;
$payne-grey: #3A3A49;
$black-russian: #151517;
$black: #000000;
$red: #fd2a2a;
$dark-red: #c84b4b;
$green-light: #a5c63b;
$green: #28A38D;
$sky-blue: #2f94e9;
$tory-blue: #384f92;
$bg-blue: #5467A3;
$mariner: #4963b2;
$corn-flower-blue: #3e3e66;
$ink-blue: #313363;
