.AccountSettings {
  @media (max-width: 768px) {
    > .contentContainer {
      margin-top: 0 !important;
    }
    .contentContainer .contentContainer {
      margin-top: 20px;
    }
  }

  .accountSettingsAvatarContainer {
    padding-top: 27px;
    padding-bottom: 40px;
  }

  .avatar_ {
    width: 164px;
    height: 164px;
    position: relative;
    margin-left: 90px;

    @media (max-width: 576px) {
      width: 104px;
      height: 104px;
      margin-left: 0;

      .avatarSettingsButton {
        right: -10px;
        bottom: -4px;
      }
    }
  }

  .LanguageSelector_ {
    padding-top: 27px;
    padding-bottom: 40px;

    #select-dropdown .btn {
      width: 100%;
    }
  }

  @media (max-width: 576px) {
    .field-column {
      width: 100%;
    }
  }

  .bg-red {
    background-color: #fd2a2a !important;
    color: rgb(255, 255, 255);
    font-size: 14px;
    line-height: 18px;
    border-radius: 50px;
    padding: 12px 0;

    &:focus, &:active {
      background-color: #fd2a2a !important;
    }
  }
}
