.ImportanceModal {
  --dark: #313363;
  --bg-dropdown: #fff;
  --color: #282939;
  --cacel-btn-color: #313363;
  --file-bg: #f2f6f9;
  --file-txt: #9bafca;
  --cancel-btn-bg: #f2f6f9;
  --preview-bg: #ebeef3;
  --preview-border: 1px solid #e1e6ed;
  --preview-color: #39406077;
}

body.dark .ImportanceModal {
  --dark: #384f92;
  --bg-dropdown: #151517;
  --color: #ffffff;
  --cacel-btn-color: #fff;
  --file-bg: #242435;
  --file-txt: #b9b9b9;
  --cancel-btn-bg: #3a3a49;
  --preview-bg: #151517;
  --preview-border: 1px solid #3B3C3E;
  --preview-color: #9BAFCA;

  .file_item {
    background-color: #121218;

    span {
      color: #b9b9b9;
    }
  }

  .plus_files {
    background-color: #121218;
    color: #b9b9b9;
  }
}

.ImportanceModal {
  .modal-body {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 185px);

    scrollbar-color: #313363 slategrey !important;
    scrollbar-width: thin !important;
    border-bottom: none !important;

    @media (max-width: 768px) {
      padding: 15px !important;
      overflow: hidden !important;
    }

    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #384f92 !important;
      outline: none !important;
    }
  }

  .group-members-modal .searchSectionContainer {
    border-radius: 38px !important;
  }

  .context {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 95px);
    height: calc(100% - 95px);

    .user-bar {
      display: flex;
      flex-direction: column;
      max-height: inherit;
      height: 100%;
    }

    .contactInfoCellContainer {
      p {
        width: 100%;
        max-width: 182px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      @media (max-width: 1100px) {
        &.department,
        &.position,
        &.suffix,
        &.group {
          display: none;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 0;

      .user-bar {
        padding: 0;
        margin-top: unset;
        margin-bottom: 15px;
      }
    }
  }

  &.hasImportance .context {
    height: 100%;
    max-height: calc(70vh - 160px);
  }

  .group-scroll_ {
    overflow: auto;
  }

  .contactsListSection {
    max-height: unset;
    padding: 10px 35px;
    flex: 1;
    border-bottom: none !important;

    margin: 0 -35px;

    @media (max-width: 768px) {
      margin: 10px -15px;
      padding: 0 15px;
    }

    button.add,
    button.add:hover {
      background: var(--dark) url(/static/media/add-member-white.4dd84184.svg)
        center center no-repeat !important;
    }

    button.remove,
    button.remove:hover {
      background: #f2f6f9 url(/static/media/remove-member-blue.498126d2.svg)
        center center no-repeat !important;
    }

    .no_result {
      display: block;
      text-align: center;
      padding: 20px;
    }

    .Item + .no_result {
      display: none;
    }

    // Firefox cutomise scrollbar
    scrollbar-color: #313363 slategrey !important;
    scrollbar-width: thin !important;

    &::-webkit-scrollbar {
      width: 0.3em;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #384f92 !important;
      outline: none !important;
    }
  }

  .modal-footer {
    border-top: none !important;

    @media (max-width: 768px) {
      margin: 0 30px;
    }

    .cancel_btn {
      background-color: var(--cancel-btn-bg) !important;
      color: var(--cacel-btn-color) !important;
    }

    button {
      font-family: inherit !important;
      font-weight: bold;
      width: 143px !important;
      height: 43px !important;
      font-size: 14px !important;
    }
  }

  &.SINGLE {
    max-width: 403px !important;
    margin-top: 20vh;

    .seperator {
      display: none;
    }

    &.hasImportance {
      max-height: 265px !important;
    }

    .modal-footer {
      border-top: none !important;
    }

    .cancel_btn {
      background-color: var(--cancel-btn-bg) !important;
      color: var(--cacel-btn-color) !important;
    }

    .modal-body {
      overflow: unset;
    }

    @media (max-width: 768px) {
      .modal-content {
        height: unset !important;
      }

      .modal-body {
        height: unset !important;
        overflow: auto !important;
      }
    }
  }

  .title {
    margin-bottom: 5px;
    margin-left: 30px;
  }

  @media (max-width: 1100px) {
    .contactsListSection .contactSectionContainer {
      margin-left: 0 !important;
    }
  }

  .seperator {
    background: #3d3d55;
    margin: 11px 0;
    padding: 0.5px;
    opacity: 0.2;
  }

  .clo.dropdown,
  .phone-container {
    // margin-bottom: 20px;
    margin-left: 30px;

    @media (max-width: 768px) {
      margin-left: 0px;
    }

    .dropdown-menu.show {
      width: 250px;
    }

    > button {
      padding: 8px 14px;
      width: 330px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 30px;
      border: none;
      background: var(--bg-dropdown) !important;
      color: var(--color) !important;
      height: 43px;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .searchSectionContainer input[type="text"] {
    height: 43px;
  }

  .phone-container {
    color: var(--cacel-btn-color);
    margin-top: 15px;

    .phone_check {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      label {
        margin: 0;
      }
      input {
        margin-right: 10px;
      }
    }

    .phone_input {
      display: flex;
      align-items: center;
      background-color: var(--bg-dropdown);

      max-width: 330px;
      border-radius: 20px;
      margin-bottom: 6px;
      height: 43px;

      .dropdown {
        button {
          width: 60px !important;
          background-color: transparent !important;
          height: 45px;
          border: none !important;
          color: var(--color) !important;
        }
      }

      .phone-number {
        background-color: transparent;
        border: none;
        outline: none;
        flex: 1;
        height: 40px;
        color: var(--color) !important;
      }
    }
  }

  .msg_preview {
    margin: 15px 30px 0 30px;

    @media (max-width: 768px) {
      margin: 0;
    }

    h2 {
      font-size: inherit;
      color: inherit;
    }

    .preview_content {
      padding: 18px;
      background: var(--preview-bg);
      border: var(--preview-border);
      border-radius: 21.5px;

      .text {
        color: var(--preview-color);
        word-wrap: break-word;

        &.group {
          white-space: nowrap;
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .files {
        display: flex;
        justify-content: start;
        align-items: center;

        .plus_files {
          display: inline-block;
          background-color: var(--file-bg);
          color: var(--file-txt);
          font-size: 12px;
          margin-left: 10px;
          height: 36px;
          width: 36px;
          font-family: inherit;
          padding: 8px;
          text-align: center;
          border-radius: 11px;
          font-weight: 700;
        }

        .file_item { 
          background-color: var(--file-bg);
          margin: 3px 2px 2px;
          padding: 7px;
          border-radius: 11px;
          display: inline-flex;
          align-items: center;
          position: relative;

          &._2 {
            margin-left: 10px;
          }

          span {
            display: inline-block;
            max-width: 190px;
            width: 140px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 10px;
            font-weight: 700;
            margin-left: 2px;
            color: var(--file-txt);

            @media (max-width: 768px) {
              width: 70px;
            }
          }
        }
      }
    }
  }
}

.CheckBox_ {
  align-items: center;

  p {
    color: inherit !important;
  }
}
