@import './colors.scss';

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.svg') format('svg');
  src: url('./assets/fonts/Montserrat/Montserrat-Regular.eot') format('eot');
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 100%;
  height: 100%;

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
    font-variant-ligatures: none;
    -webkit-overflow-scrolling: touch;

    #root {
      height: 100%;
    }

    .appContainer {
      height: 100%;
      margin: 0 auto;
      position: relative;
      .pageContainer {
        margin-left: 78px;
        width: calc(100vw - 78px);
        height: 100vh;
        padding: 0;
        overflow-y: auto;
      }


      .pagination {
        display: inline-block;
        padding-left: 0;
        margin: 20px 10px;
        border-radius: 4px;

        li {
          display: inline;
          cursor: pointer;

          a,
          span {
            position: relative;
            float: left;
            padding: 4px 12px;
            margin-left: -1px;
            line-height: 1.42857143;
            color: $red;
            font-weight: 700;
            text-decoration: none;

            &:hover,
            &:focus {
              z-index: 2;
              color: $red;
              background-color: #eee;
              border-color: #ddd;
              outline: none;
            }
          }

          &:first-child {
            a,
            span {
              margin-left: 0;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
            }
          }

          &:last-child {
            a,
            span {
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px;
            }
          }
        }

        .active {
          a,
          span,
          a:hover,
          span:hover,
          a:focus,
          span:focus {
            z-index: 3;
            color: $ink-blue;
            cursor: default;
            outline: none;
          }
        }

        .disabled {
          a,
          span,
          a:hover,
          span:hover,
          a:focus,
          span:focus {
            color: #777;
            cursor: not-allowed;
            border-color: #ddd;
          }

          &.next,
          &.previous {
            a,
            span,
            a:hover,
            span:hover,
            a:focus,
            span:focus {
              background: #777 !important;
              color: #ebeef3 !important;
              border-radius: 17px !important;
              cursor: not-allowed !important;
            }

          }
        }

        .next,
        .previous {
          font-size: 13px;

          a,
          span,
          a:hover,
          span:hover,
          a:focus,
          span:focus {
            background: $ink-blue;
            color: #ebeef3;
            border-radius: 17px !important;
          }
        }
      }

      .pagination-lg {
        li {
          a,
          span {
            padding: 10px 16px;
            font-size: 18px;
            line-height: 1.3333333;
          }

          &:first-child {
            a,
            span {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px;
            }
          }

          &:last-child {
            a,
            span {
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px;
            }
          }
        }
      }

      .pagination-lg {
        li {
          a,
          span {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 1.5;
          }

          &:first-child {
            a,
            span {
              border-top-left-radius: 3px;
              border-bottom-left-radius: 3px;
            }
          }

          &:last-child {
            a,
            span {
              border-top-right-radius: 3px;
              border-bottom-right-radius: 3px;
            }
          }
        }
      }

      .copyButton {
        background: url("/assets/images/message-copy-normal.svg") no-repeat;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        cursor: pointer;
        background-size: cover;

        &:hover {
          background: url("/assets/images/message-copy-hover.svg") no-repeat;
        }

        &:focus,
        &:active {
          background: url("/assets/images/message-copy-pressed.svg") no-repeat;
        }
      }

      .contentContainer {
        padding: 0 30px;
        @media (max-width: 641px) {
          padding: 0;
          flex-wrap: wrap;
        }

        .loaderContainer {
          display: flex;
          flex: 1;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center;
        }
      }

      .advancedSearchComponentContainer {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding: 5px 16px 20px;

        > label {
          margin-bottom: 0;
          margin-right: 5px;
          line-height: 18px;
          max-height: 44px;
          display: flex;

          img {
            width: 18px;
          }
        }

        .advancedSearchContainer {
          flex: 1;

          .advancedSearch__control {
            font-family: 'Montserrat', sans-serif;
            font-size: 15px;
            background: transparent;
            box-shadow: none;
            border: 0;
            margin-left: 5px;
            height: 44px;
            outline: none;
            color: #9bafca;
            flex-grow: 1;

            &:focus {
              box-shadow: none;
            }

            .advancedSearch__placeholder {
              color: #9bafca;
            }

            .advancedSearch__input {
              color: #9bafca;

            }
          }
        }

        .advancedSearch__menu {
          background-color: $alice-blue;
          z-index: 1000;
        }

        .advancedSearch__option {
          cursor: pointer;
        }

        .advancedSearch__option--is-focused {
          background: transparent;
          color: $green;
          cursor: pointer;
        }

      }

      textarea {
        resize: none;
      }

      select.inputField {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 0 19px !important;
        background: url("/assets/images/arrow-down.svg") no-repeat 90% 17px $white;

        option[data-type="placeholder"] {
          display: none;
        }
      }

      .submitButton {
        background-color: $ink-blue;
        color: $white;
        font-size: 14px;
        line-height: 18px;
        border-radius: 50px;
        padding: 12px 0;


        &.disabled {
          background-color: $ink-blue !important;
          border-color: $ink-blue !important;
          opacity: 0.4;
          box-shadow: none;
        }

        &:focus,
        &:active {
          background-color: $ink-blue !important;
          box-shadow: none !important;
        }

      }

      .cancelButton {
        background-color: $green-light;
        border-color: $green-light !important;
        color: $white;
        font-size: 14px;
        line-height: 18px;
        border-radius: 50px;
        padding: 15px 0;

        &.disabled,
        &:active,
        &:focus {
          background-color: $green-light !important;
          border-color: $green-light !important;
          opacity: 0.4;
          box-shadow: none;
        }
      }

      .linkButton {
        font-size: 14px;
        color: $ink-blue;
        line-height: 18px;
        background-color: transparent;
        border: none;

        &:hover {
          text-decoration: none;
        }

        &.disabled {
          opacity: 0.4;
          box-shadow: none;
        }

        &:focus {
          box-shadow: none !important;
          color: $ink-blue;
        }

        &:active {
          background: none !important;
          color: $ink-blue !important;
          box-shadow: none;
        }
      }

      .statusButtonContainer {
        button {
          width: auto;
          height: 15px;
          font-family: Montserrat, sans-serif;
          font-size: 10px;
          color: #ffffff;
          line-height: 0;
          border: 0;
          border-radius: 15px;
          padding: 0 22px 0 6px;
          text-align: left;

          &.admin {
            background-color: #a5c63b;
          }

          &.facilityAdmin {
            background-color: blue;
          }

          &.regular {
            background-color: #fd2a2a;
          }

          &.success {
            background-color: #a5c63b;
          }

          &.warning {
            background-color: #efba09;
          }

          &.danger {
            background-color: #fd2a2a;
          }

          &.passive, &.disabled {
            padding: 0 14px;
            opacity: 1;

            &::after {
              display: none;
            }

            &::before {
              display: none;
            }
          }

          &:focus {
            box-shadow: none !important;
          }

          &::after {
            display: inline-block;
            vertical-align: .255em;
            content: "";
            border-top: .4em solid;
            border-right: .3em solid transparent;
            border-bottom: 0;
            border-left: .3em solid transparent;
            position: absolute;
            right: 6px;
            top: 6px;

          }

          &::before {
            content: "";
            width: 0px;
            height: 15px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            display: block;
            position: absolute;
            top: 0px;
            right: 16px;
          }

          & + .dropdown-menu {
            padding: 11px 18px;
            width: 159px;
            // height: 110px;

            button {
              background: none;
              color: #282939;
              padding: 0;
              width: 100%;
              position: relative;
              line-height: 14px;
              font-size: 14px;
              margin: 7px 0px;

              &::after {
                display: none;
              }

              &::before {
                content: "";
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #a5c63b;
                border: 2px solid #fff;
                position: relative;
                z-index: 999;
                display: block;
                float: left;
                left: 0;
                bottom: 0;
                margin-right: 6px;
              }

              &.item-warning {
                &::before {
                  background: #efba09;
                }

              }

              &.item-danger, &.item-regular {
                &::before {
                  background: #fd2a2a;
                }
              }

              &.item-facilityAdmin {
                &::before {
                  background: blue;
                }
              }
            }
          }
        }
      }

      .dropdown-menu {
        z-index: 9999;
        padding: 10px 0;
        border: 1px solid #313363;
        background-color: #ffffff;
        border-radius: 10px;
      }

      .settingIconContainer {
        background-color: $ink-blue;
        width: 35px;
        height: 35px;
        border-radius: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 12px;
        cursor: pointer;
      }

      .avatarSettingsButton {
        position: absolute;
        right: 0;
        bottom: 0px;

        .dropdown-toggle {
          background-color: unset !important;
          box-shadow: unset !important;
          border: none !important;
          outline: none !important;
        }

        .dropdown-item {
          border: none !important;
          outline: none !important;

          &.remove-button {
            &:hover {
              color: $red;
            }

            &:active {
              background: $tencSeriot;
            }
          }
        }

        input[type="file"] {
          display: none;
        }
      }
    }
  }
}

i.icon-identifier {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  background: $ink-blue;
  margin-right: 10px;


  &.user {
    background-color: $sky-blue;
  }

  &.admin {
    background-color: $red;

  }
}

.inputField {
  border-radius: 50px !important;
  padding: 16px 19px !important;;
  line-height: 18px !important;;
  font-size: 14px !important;;
  border-color: $pale-blue !important;;
  height: 43px !important;

  &::-webkit-input-placeholder { /* Edge */
    color: #9bafca !important;;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9bafca !important;;
  }

  &::placeholder {
    color: #9bafca !important;;
  }

  &:hover {
    border-color: #9bafca !important;;
  }

  &#userSessionTimeout {
    width: auto !important;;
  }

  &:focus {
    border-color: #313363 !important;;
    color: $ink-blue-50 !important;;
    box-shadow: none !important;;

    &::-webkit-input-placeholder { /* Edge */
      color: $ink-blue-50 !important;;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $ink-blue-50 !important;;
    }

    &::placeholder {
      color: $ink-blue-50 !important;;
    }
  }
}

.searchSectionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 5px 16px 0;

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 10px;
      height: 10px;
    }
  }

  input[type=text] {
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    background: transparent;
    border: 0;
    margin-left: 5px;
    height: 44px;
    outline: none;
    color: #9bafca;
    flex-grow: 1;

    &:focus {
      box-shadow: none;
    }
  }

  label {
    margin-bottom: 0;
    margin-right: 5px;
    line-height: 18px;

    img {
      width: 18px;
    }
  }

  ::-webkit-input-placeholder { /* Edge */
    color: #9bafca;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9bafca;
  }

  ::placeholder {
    color: #9bafca;
  }

}

.rc-checkbox.escCheckbox:hover .rc-checkbox-inner, .rc-checkbox-input:focus + .rc-checkbox-inner {
  border-color: $ink-blue !important;
}

.rc-checkbox-inner {
  width: 16px;
  height: 16px;
}

.rc-checkbox-checked .rc-checkbox-inner {
  border-color: $ink-blue !important;
  background-color: $ink-blue !important;
}

.rc-checkbox.escCheckbox .rc-checkbox-inner {
  border: 1px solid $ink-blue !important;
}

.escCheckboxContainer {
  display: flex;

  p {
    color: $ink-blue-50;
    margin: 0 10px;
  }
}

.multiSelectDropdown {
  &.open > button {
    border-color: #313363 !important;
  }

  .multiSelectTitle {
    min-width: 90%;
    text-align: left;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white !important;
    color: $ink-blue-50 !important;
    border-radius: 50px !important;
    line-height: 18px;
    font-size: 14px;
    border-color: transparent !important;
    height: 43px;
    min-width: 200px;
    padding: 6px 18px;
    max-width: 100%;

    span {
      display: flex;
      align-items: center;
    }

    p {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      max-width: 95%;
      margin: 0;
    }

    &:hover {
      border-color: #9bafca;
    }

    &:active,
    &:focus {
      box-shadow: none;
    }

  }

  > .dropdown-menu {
    border: 1px solid #313363;
    border-radius: 20px !important;
    min-width: 100%;
    top: 8px !important;


    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    .searchSectionContainer {
      margin: 0 0 14px;
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid #3940601A;

      input {
        padding-left: 0;
        color: $ink-blue-50;
        height: 30px;

        &::-webkit-input-placeholder { /* Edge */
          color: $pale-blue-dark;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: $pale-blue-dark;
        }

        &::placeholder {
          color: $pale-blue-dark;
        }
      }

    }

    .optionsContainer {
      padding: 0 16px;
      max-height: 150px;
      overflow-y: auto;
    }

  }
}

.divider {
  height: 1px;
  width: 100%;
  border-top: 1px solid $dark-blue;
  opacity: 0.1;
}

.avatarContainer {
  color: $white;
  font-size: 15px;
  line-height: 19px;
  width: 45px;
  min-width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  &.loadingState {
    border: 1px solid $ink-blue;
    border-radius: 50%;
  }

  &.withStatus {
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $pale-blue-dark;
      border: 2px solid #fff;
      position: absolute;
      z-index: 90;
      display: block;
      right: 0px;
      bottom: 0px;
    }

    &.available {
      &::before {
        background: #a5c63b;
      }
    }

    &.away {
      &::before {
        background: #efba09;
      }
    }

    &.do-not-disturb {
      &::before {
        background: #fd2a2a;
      }

    }

    &.offline {
      &::before {
        background: #b2bdcd;
      }

    }
  }

  &:hover {
    text-decoration: none;
  }

  &.small {
    width: 20px;
    min-width: 20px;
    height: 20px;
    font-size: 10px;
    overflow: hidden;
  }

  &.big {
    width: 164px;
    height: 164px;
    font-size: 45px;
    overflow: hidden;
    
    @media  (max-width: 576px) {
      width: 104px;
      height: 104px;
    }
  }

  .avatarHolder {
    width: 100%;
    height: 100%;
    border-radius: 80px !important;
    background-color: #28A38D;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img, object {
      width: 100%;
      height: 100%;
      border-radius: 80px !important;
      object-fit: cover;
    }
  }
}

.professionalInformationContainer {
  .form-group {
    width: 100%;
  }

  .field-column {
    width: 362px !important;
  }

  @media  (max-width: 576px){
    .field-column {
      width: 100% !important;
      max-width: calc(100vw - 108px);

      .submitButton {
        max-width: inherit;
        width: inherit !important;
      }
    }
  }
}

.clickable {
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.errorText {
  color: $red !important;
  font-size: 14px !important;
  opacity: 1 !important;
  width: 100% !important;
}

.lostConnectionMessage {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2px);
  background-color: $shuttle-grey;
  opacity: 0.5;

  .lostConnectionMessageContent {
    padding: 60px 10px;
    max-width: 200px;
    background-color: $black;
    opacity: 0.9;
    border-radius: 25px;
    color: $white-50;
    text-align: center;

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
    }
  }
}

#select-dropdown {
  color: #495057;

  .btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;

    span {
      min-height: 24px;
    }
  }

  .btn, .dropdown-menu {
    width: 362px;
    color: inherit;
    background-color: #fff;
    outline: none;
    border-bottom: 1px solid #d0d5df;
    border-top: 1px solid #d0d5df;
    border-left: none;
    border-right: none;
    border-radius: unset;
  }

  .optionsContainer {
    max-height: 500px;
    overflow: auto;
  }
}

.modalSearchIcon {
  cursor: pointer;

  &:hover {
    opacity: 0.7
  }
}

.sortButtonsContainer {
  img:first-child {
    transform: rotate(180deg);
  }

  img {
    cursor: pointer;
    opacity: 0.3;

    &:hover {
      opacity: 0.6;
    }

    &.selected {
      opacity: 1;
    }
  }
}

video {
  pointer-events: none;
}

.modal-content {
  overflow: visible !important;
}

.tooltip {
  &.show {
    z-index: 99999;
  }
}

html body .appContainer .pageContainer{
  height: 100vh;

  @media (max-width:768px) {
    height: -webkit-fill-available !important;
    min-height: -webkit-fill-available !important;
  }
}




@import "./darkStyles";
