.MessageViewList {
    position: absolute;
    bottom: 12px;
    right: -24px;
    // display: none;

    @media (max-width: 768px) {
        right: -30px;
    }

    .content__ {
        position: absolute;
        bottom: 30px;
        right: 0;
        background-color: #fff;
        width: 350px;
        border-radius: 14px;
        padding: 20px;
        font-size: 15px;
        max-height: 400px;
        overflow: auto;

        span {
            text-align: center;
        }
        
        span, small {
            color: #9BAFCA;
            font-size: 12px;
            white-space: nowrap;
        }

        > b {
            margin-bottom: 20px;
            display: block;
        }

        > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
        }

        .item_ {
            > div {
                display: flex;

                > div {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    button {
        background-color: transparent;
        border: none;
        outline: none !important;
    }
}

.dark {
    .MessageViewList {    
        .content__ {
            background-color: #000;
            color: #fff;
        }
    }
}