.RichTextEditor {
  height: 100%;
  width: 100%;
  background-color: #fff;

  >div {
    height: 100%;
    width: 100%;
  }

  blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: Hoefler Text, Georgia, serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
  }

  .ql-toolbar.ql-snow .ql-formats {
    background: #eee;

    @media (max-width: 768px) {
      margin-bottom: 4px;

      button {
        height: 28px;
        width: 35px;
      }
    }
  }

  iframe {
    display: none;
  }
}