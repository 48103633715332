@media  (max-width: 576px){
    .chatMessageContainer{
        width: 100vw !important;
        margin-left: 0px !important;
    }
    .chatMessageContainer .pageContainer{
        width: 100vw !important;
    }

}
