.AudioRecorder {
  --pause: #384F92;
  --timer: #6D737B;
  --player-bg: #E1E6ED;
}

body.dark .AudioRecorder {
  --pause: #313363;
  --player-bg: #3D3D51;
  --timer: #fff;
}

.AudioRecorder {
  border-radius: 20px;
  box-sizing: border-box;
  color: #000000;
  width: 100%;
  height: 80px;

  display: flex;
  align-items: center;

  transition: all 0.2s ease-in;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 365px) {
    max-width: calc(100vw - 65px);
  }

  .AudioPlayer {
    background-color: var(--player-bg);
    padding: 6px 12px;
    border-radius: 30px;
  }

  .status {
    color: #000000;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }
  
  .status {
    display: flex;
    align-items: baseline;
    animation-name: fading-ar-status;
    animation-duration: 2s;
    animation-iteration-count: infinite;

    &.pause {
      animation: none;
    }
  }
  
  .status-dot {
    background-color: #C84B4B;
    border-radius: 50%;
    height: 12px;
    width: 12px;
    margin-right: 5px;
  }

  .options {
    box-sizing: content-box;
    height: 26px;
    cursor: pointer;
    padding: 12px 6px 12px 12px;

    &.pause {
      background-color: var(--pause);
      height: 16px;
      padding: 10px;
      border-radius: 50%;
      margin-right: 18px;
    }
  }

  .visualizer {
    margin-left: 15px;
    flex-grow: 1;
    align-self: center;
    display: flex;
    align-items: center;
  }

  .timer {
    color: var(--timer);
  }
}



@keyframes fading-ar-status {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
