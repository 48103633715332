.PasswordUpdate {
  @media (max-width: 1250px) {
    display: flex;
    justify-content: center;
  }

  .content_ {
    display: flex;
    margin-top: 30px;
    align-items: center;
    position: relative;
    margin: 40px 2% 20px 2%;
    flex-wrap: wrap;

    @media (max-width: 1250px) {
      max-width: 59vw;
    }

    @media (max-width: 768px) {
      max-width: 59vw;
      flex-direction: column;
      align-items: center;
      margin: 60px 0% 20px 0%;
      min-width: 280px;

      > * {
        max-width: 280px;
        width: 280px;
      }
    }

    > p {
      position: absolute;
      top: -30px;
    }

    > div {
      margin-right: 3%;

      @media (max-width: 1250px) {
        width: 100%;
        justify-content: center;
      }
    }
  }

  p.title {
    opacity: 0.5;
    margin-bottom: 5px;
  }

  .schedule_status {
    .multiSelectDropdown {
      width: 280px;

      .dropdown-menu {
        width: 280px !important;
      }

      @media (max-width: 1250px) {
        width: 360px;
        justify-content: center;

        .dropdown-menu {
          width: 360px !important;
        }
      }

      @media (max-width: 768px) {
        max-width: 280px;
      }
    }
  }

  .frequency_inputs {
    display: flex;

    &.disabled {
      opacity: 0.6;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .input_ {
      width: 90px;
      min-width: 90px;
      margin: 0;
      margin-right: 5px;

      @media (max-width: 768px) {
        max-width: 75px;
        min-width: 75px;
      }

      input {
        background-color: #fff !important;
      }
    }

    .multiSelectDropdown {
      width: 280px;

      .dropdown-menu {
        width: 280px !important;
      }

      @media (max-width: 1250px) {
        width: 265px;
        .dropdown-menu {
          width: 265px !important;
        }
      }

      @media (max-width: 768px) {
        max-width: 160px;
        .dropdown-menu {
          width: 160px !important;
        }
      }
    }
  }

  .submitButton {
    margin-top: 26px;
    width: 130px !important;
    min-width: 130px;

    @media (max-width: 768px) {
      width: 280px !important;
      min-width: 280px;
    }
  }
}

body.dark .PasswordUpdate {
  p.title {
    opacity: 0.7;
    color: #6d737b;
  }

  .content_ > p {
    color: #5e636c;
  }
}
