.SpeechToText {
  display: flex;
  align-items: center;
  margin-right: 10px;
  
  > div {
    background-color: #313363;
    border-radius: 60px;
    height: 46px;

    display: flex;
    align-items: center;
    padding: 10px;

    span {
      color: #fff;
      white-space: nowrap;

      @media (max-width: 768px) {
        display: none;
      }
    }

    > svg {
      margin: 0 10px;

      .bars {

      }
    }
  }
}
